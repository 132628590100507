<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">{{ $t("WAREHOUSES.WAREHOUSES_LIST") }}</h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="applyConmfigurations()"
                v-if="
                  $currentUserCan($permissions.PERM_VIEW_ANY_WAREHOUSES) &&
                  selectedWarehouses.length > 0
                "
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-check"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("COMMON.APPLY_CONFIGURATION") }}
                </span>
              </base-button>

              <base-button
                type="primary"
                icon
                size="sm"
                @click="addWarehouse()"
                v-if="$currentUserCan($permissions.PERM_CREATE_WAREHOUSES)"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("WAREHOUSES.ADD_WAREHOUSE") }}
                </span>
              </base-button>

              <notification-subscription
                v-if="$currentUserCan($permissions.PERM_VIEW_WAREHOUSES)"
                :objectType="'warehouses'"
                :events="{
                  CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>

        <warehouse-list-table
          :isModel="false"
          @warehousesSelected="
            (warehouses) => (selectedWarehouses = warehouses)
          "
        />
      </card>
    </div>

    <div v-if="showResults">
      <warehouse-view-warehouse-configured-results
        v-for="(configurationResult, name) of configurationResults"
        :modelName="name"
        :key="configurationResult.id"
        :configurationResult="configurationResult"
      />
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import WarehouseListTable from "./partials/WarehouseListTable.vue";
import WarehouseViewWarehouseConfiguredResults from "./partials/WarehouseViewWarehouseConfiguredResults.vue";

export default {
  layout: "DashboardLayout",

  components: {
    WarehouseListTable,
    NotificationSubscription,
    WarehouseViewWarehouseConfiguredResults,
    [Button.name]: Button,
  },

  mixins: [],

  data() {
    return {
      selectedWarehouses: [],
      configurationResults: [],
      showResults: false,
    };
  },

  computed: {},

  watch: {},

  methods: {
    addWarehouse() {
      this.$router.push({ name: "Add Warehouse" });
    },

    async applyConmfigurations() {
      const confirmation = await swal.fire({
        title: this.$t("WAREHOUSES.APPLY_CONFIGURATION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (!confirmation.value) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      for (const warehouse of this.selectedWarehouses) {
        try {
          const results = await this.$store.dispatch(
            "warehouses/applyConfiguration",
            warehouse.id
          );
          this.configurationResults = {
            ...this.configurationResults,
            [warehouse.name]: results,
          };
        } catch (error) {
          await this.showRequestError(error);
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      }
      this.showResults = true;
      swal.close();
    },
  },
};
</script>
