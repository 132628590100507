var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("WAREHOUSES.WAREHOUSES_LIST")))])]),_c('div',{staticClass:"col-6 text-right"},[(
                _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_WAREHOUSES) &&
                _vm.selectedWarehouses.length > 0
              )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.applyConmfigurations()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-check"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.APPLY_CONFIGURATION"))+" ")])]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_WAREHOUSES))?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.addWarehouse()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-plus"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("WAREHOUSES.ADD_WAREHOUSE"))+" ")])]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_WAREHOUSES))?_c('notification-subscription',{attrs:{"objectType":'warehouses',"events":{
                CREATE: _vm.$t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}}):_vm._e()],1)])]),_c('warehouse-list-table',{attrs:{"isModel":false},on:{"warehousesSelected":(warehouses) => (_vm.selectedWarehouses = warehouses)}})],2)],1),(_vm.showResults)?_c('div',_vm._l((_vm.configurationResults),function(configurationResult,name){return _c('warehouse-view-warehouse-configured-results',{key:configurationResult.id,attrs:{"modelName":name,"configurationResult":configurationResult}})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }